import allText from "@cms/404.json";
import { ErrorPageText } from "@cms/types";
import LinkToExternalPage from "@components/navigation/helpers/LinkToExternalPage";
import selectText from "@helpers/selectText";
import { Button, Container, Typography, useTheme } from "@material-ui/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

const imageRatio = (size: number) => {
  return {
    width: `${510 * size}px`,
    height: `${400 * size}px`,
  };
};

const imageSize = imageRatio(0.8);

export default function Custom404() {
  const theme = useTheme();
  const router = useRouter();
  const language = router.locale;
  const text = selectText(allText, language as "nl"|"fr"|"en") as ErrorPageText;

  return (
    <Container style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          height: "100%",
          width: "100%",
        }}
      >
        <div style={{ maxWidth: "500px", paddingRight: theme.spacing(4) }}>
          <Typography variant="h2" style={{ fontSize: "24pt", marginBottom: theme.spacing(4) }}>
            {text.title}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: theme.spacing(4) }} gutterBottom>
            {text.text}
          </Typography>
          <div style={{ display: "flex" }}>
            <Link href="/" passHref>
              <Button variant="contained" color="secondary" fullWidth style={{ maxWidth: "250px" }}>
                {text.backToToolsbutton}
              </Button>
            </Link>
            <div style={{ width: theme.spacing(4) }}></div>
            <LinkToExternalPage url={"https://www.acerta.be"}>
              <Button variant="contained" color="secondary" fullWidth style={{ maxWidth: "250px" }}>
                {text.backToAcertaButton}
              </Button>
            </LinkToExternalPage>
          </div>
        </div>
        <Image src="/images/404.png" width={imageSize.width} height={imageSize.height} />
      </div>
    </Container>
  );
}
